import { RichText } from "prismic-reactjs"; 

export default ({ 
    data: {intro, partners},
    id
}) => ({
	id,
    title: RichText.render(intro),
    partners : partners && partners.map(({logo})=>logo.url),

});
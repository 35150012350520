import { createBreakpoint } from 'styled-components-breakpoint';
export default {
    colors: {
        green: '#6CD094',
        grey: '#EFEFEF',
        subgrey: '#999999',
        red: '#FE6219',
        yellow: '#FFD26E',
        orange: '#FFA851',
        purple: '#2D329F',
        pink: '#FFA397',
        black: '#1F1F1F',
        white: '#FFFFFF',
        cream: '#FFFBEF'
    },
    font: {
        family: {
            primary: "founders-grotesk",
            secondary: "Source Sans Pro"
        }

    },
    breakpoint: createBreakpoint({
        xs: 0,
        sm: 320,
        cm: 600,
        md: 768,
        lg: 1024,
        xl: 1439
    })
}
import { RichText } from "prismic-reactjs"; 

export default ({ 
    data,
    id
}) => ({
	id,
    quote: RichText.render(data.quote),
    principles: data.values && data.values.map(
        ({logo, title, description }) => ({
            src: logo.url,
            title: RichText.render(title),
            description: RichText.render(description),
        })
    )
});
import React from "react"
import Grid from "../components/Grid"
import Theme from "../Theme"
import styled from "styled-components"
import { Typography } from "../styles"
import { Title } from "./Work"

const PersonContainer = styled.div`
    grid-column: span 3;
    margin-bottom: 30px;
    ${Theme.breakpoint("md")`
        grid-column: span 4;
        margin-bottom: 70px;
    `}
    ${Theme.breakpoint("lg")`
        grid-column: span 3;
        margin-bottom: 105px
    `}
`

const HoverPerson = styled.img`
    display: block;
    width: 100%;
    /* min-height: 163px;
    max-height: 406.9px; */
    border: none;
    object-fit: cover;
    object-position: center;
    ${Theme.breakpoint("md")`
        // height: 350px;
    `}
    ${Theme.breakpoint("xl")`
        // height: 378.56px;
    `}
`
const Overlay = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: opacity 0.7s ease-out;
    &:hover{
        opacity: 1;
    }
`
const JoinUs = styled.div.attrs({className: "personContainerTransition", href: ""})`
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 163px;
    max-height: 406.9px;
    justify-content: center;
    align-items: center;
    border: 1px solid #E7E7E7;
    transition: background-color 0.5s ease-out;
    ${Theme.breakpoint("md")`
        height: 350px;
    `}
    ${Theme.breakpoint("xl")`
        height: 378.56px;
    `}
    &:hover, :focus, :active{
        background-color: ${Theme.colors.green};
    }
`
const JoinUsText = styled(Typography.BodyLarge)`
    color: ${Theme.colors.black};
    transition: color 0.7s ease-out;
    .personContainerTransition:hover &{
            color: ${Theme.colors.white};
        }
    .personContainerTransition:focus &{
        color: ${Theme.colors.white};
    }
    .personContainerTransition:active &{
        color: ${Theme.colors.white};
    }
`
const Name = styled(Typography.Body)`
    font-weight: 500;
    margin-top: 4.3px;
    ${Theme.breakpoint("md")`
        margin-top: 7.44px;
    `}
    ${Theme.breakpoint("lg")`
        margin-top: 10px;
    `}
`

const Role = styled(Typography.BodySmall)`
`

const TeamTitle = styled(Title)`
    /* font-weight: bold; */
    grid-row: 1;
    color: ${Theme.colors.subgrey};
    grid-column: 1 / -1;
    margin-bottom: 20px;
    border-top: 1px solid #999999;
`

const Descriptions = styled.div`
    margin: 10px 0 29px 0;
    grid-column: 1 / -1;
    ${Theme.breakpoint("md")`
        margin: 3px 0 50px 0;
    `}
    ${Theme.breakpoint("lg")`
        margin: 20px 0 111px 0;
    `}
`
const TeamDescription = styled(Typography.Header3)`
`

const Wrapper = styled.div`
    margin: 0;
    padding: 0;
    background-color: #EFEFEF;
`

const TeamGrid = styled(Grid)`
    margin-top: 0px;
    padding-top: 50px;
    padding-bottom: 80px;
    background-color: #EFEFEF;
    ${Theme.breakpoint("md")`
        padding-bottom: 140px;
        padding-top: 40px;
    `} 
    ${Theme.breakpoint("lg")`
        padding-bottom: 251px;
        padding-top: 160px;
    `}
`
const ImageContainer = styled.div`
    position: relative;
    max-width: 100%;
    top: 0;
`



function Person({ photo, name, role, mask, style}, key) {

    return (
        <PersonContainer>
            <ImageContainer style={style}>
                <HoverPerson src={photo}/>
                <Overlay src={mask}/>
            </ImageContainer>
            <Name>{name}</Name>
            <Role>{role}</Role>
        </PersonContainer>
    )
}

export default function Team({description, members, masks}) {
    const newRole = false;
    return (
        <Wrapper id="team">
            <TeamGrid id="team">
                <TeamTitle>The Team</TeamTitle>
                <Descriptions>
                    <TeamDescription>
                        {description}
                    </TeamDescription>
                </Descriptions>
                {members && masks && members.map(({ ...props }, key) => 
                    <Person {...props} mask={masks[key]} key={key}/>)}
                {newRole && <PersonContainer>
                    <ImageContainer>
                        <JoinUs>
                            <JoinUsText>Join</JoinUsText>
                        </JoinUs>
                    </ImageContainer>
                </PersonContainer>}
            </TeamGrid>
        </Wrapper>

    )
}

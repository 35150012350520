import styled from 'styled-components';
import Theme from '../Theme';

export const Typography = {};

Typography.Header1 = styled.div`
    font-size: 50px;
    line-height: 46px;
    font-weight: 400;
    letter-spacing: -0.02em;
    font-family: ${Theme.font.family.primary};
    ${Theme.breakpoint("md")`
        font-size: 90px;
        line-height: 80px;
    `}
`
Typography.Header2 = styled.div`
    font-weight: 400;
    font-size: 45px;
    line-height: 49px;
    letter-spacing: 0.02em;
    font-family: ${Theme.font.family.primary};
    ${Theme.breakpoint("md")`
        font-size: 80px;
        line-height: 90px;
    `}
`

Typography.Header3 = styled.div`
    font-weight: 300;
    font-size: 39px;
    line-height: 39px;
    letter-spacing: 0.01em;
    font-family: ${Theme.font.family.primary};
    ${Theme.breakpoint("md")`
        font-size: 71px;
        line-height: 76px;
    `}
`
Typography.MobileHeader3 = styled.div`
    font-style: normal;
    font-weight: 300;
    font-size: 39px;
    line-height: 39px;
    letter-spacing: 0.01em;
    font-family: ${Theme.font.family.primary};
`
Typography.Header4 = styled.div`
    font-weight: 400;
    font-size: 23px;
    line-height: 21px;
    font-family: ${Theme.font.family.primary};
    ${Theme.breakpoint("md")`
        font-size: 40px;
        line-height: 47.2px;
    `}
`
Typography.Title = styled.div`
    font-family: ${Theme.font.family.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21.24px;
    text-transform: uppercase;
    ${Theme.breakpoint("md")`
        font-size: 26px;
        line-height: 30.68px;
    `}
`

Typography.BodyLarge = styled.div`
    font-weight: 400;
    font-size: 20px;
    line-height: 23.6px;
    font-family: ${Theme.font.family.secondary};
    ${Theme.breakpoint("md")`
        font-size: 28px;
        line-height: 33.04px;
    `}
`

Typography.Body = styled.div`
    font-weight: 400;   
    font-size: 15px;
    line-height: 17.7px;
    font-family: ${Theme.font.family.primary};
    ${Theme.breakpoint("md")`
        font-size: 20px;
        line-height: 23.6px;
    `}
`
Typography.BodySmall = styled.div`
    font-weight: 400;   
    font-size: 14px;
    line-height: 16.52px;
    font-family: ${Theme.font.family.primary};
    ${Theme.breakpoint("md")`
        font-size: 16px;
        line-height: 18.88px;
    `}
`





import React from "react";
import styled from "styled-components";
import Grid from "./components/Grid";
import { Typography } from "./styles";
import logo from "./assets/images/roundtable-icon.svg"
import Theme from "./Theme";

const FooterGrid = styled(Grid)`
    justify-items: center;
    ${Theme.breakpoint("md")`
        margin-top: 50px;
        margin-bottom: 50px;
    `}
`

const FooterContainer = styled.div`

    padding: 50px 0 80px 0;

    ${Theme.breakpoint("md")`
        padding: 50px 0 30px 0;
    `}

    ${Theme.breakpoint("lg")`
        padding: 50px 0 30px 0;
    `}
`

const Logo = styled.img`
    width: 69.43px;
    height: 69.72px;
    grid-column-start: 1;
    grid-column-end: -1;
    margin-bottom: 40px;
    ${Theme.breakpoint("md")`
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row: 1;
        margin-bottom: 0;
        
    `}
`

const NavContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    grid-column-start: 1;
    grid-column-end: -1;
    justify-content: center;
    
    ${Theme.breakpoint("md")`
        grid-row: 1;
        grid-column: 3 / -1;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
    `}
    ${Theme.breakpoint("lg")`
        grid-row: 1;
        grid-column: 6 / -1;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
    `}
`

const NavLinks = styled.a`
    margin: 31px 50px;
    text-align: center;
    text-decoration: none;

    &:hover,:active, :focus{
        text-decoration: none;
    }
    ${Theme.breakpoint("md")`
        margin: 0 0 0 42px;
        text-align: start;
    `}
    ${Theme.breakpoint("md")`
        margin: 0 0 0 111px;
        text-align: start;
    `}
`

const CompanyDescriptionContainer = styled.div`
    grid-column-start: 1;
    grid-column-end: -1;
    margin-top: 9px;
    padding-top: 40px;
    display: block;

    ${Theme.breakpoint("md")`
        display: none;
        width: 100%;
        grid-column: 1/4;
        align-self: start;
    `}

    ${Theme.breakpoint("lg")`
        width: 100%;
        display: none;
        grid-column: 1/4;
        align-self: start;
    `}
`

const CompanyDescription = styled(Typography.BodySmall)`
    text-align: center;
    padding-bottom: 16px;
    color: #AEAEAE;
    ${Theme.breakpoint("md")`
        text-align: start;
    `}
    
`
const CompanyName = styled(Typography.BodySmall)`
    grid-column-start: 1;
    grid-column-end: -1;
    margin-top: 40px;
    color: #AEAEAE;
    ${Theme.breakpoint("md")`
        grid-row: 1 / 1;
        width: 120px;
        grid-column-start: 12;
        grid-column-end: -1;
        align-self: end;
        margin-top: 50px;
    `}
`
export default function Footer({links, descriptions}) {
    return (<FooterContainer>
            <FooterGrid>
                <Logo src={logo}></Logo>
                <NavContainer>
                    {links && links.map(({text, url}, key) => <NavLinks key={key} href={url} target="_blank"> <Typography.Body>{text}</Typography.Body></NavLinks>)}
                </NavContainer>
            </FooterGrid>
            <FooterGrid style={{marginBottom: 0}}>
                <CompanyDescriptionContainer>
                    {descriptions && descriptions.map((text, key) => <CompanyDescription key={key} >{text}</CompanyDescription>)}
                </CompanyDescriptionContainer>
                <CompanyName>Roundtable Studio</CompanyName>
            </FooterGrid>
        </FooterContainer>)
}
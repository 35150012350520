import React from "react";  
import styled from "styled-components";
import Theme from "../Theme";

const CustomGrid = styled.div`
    /* mobile */
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    margin: 0 10px;    
    
    /* tablet */
    ${Theme.breakpoint("md")`
        grid-column-gap: 20px;
        grid-template-columns: repeat(12, 1fr);
        margin: 0 20px;
    `}
    
    /* desktop */
    ${Theme.breakpoint("lg")`
        grid-column-gap: 20px;
        grid-template-columns: repeat(12, 1fr);
        max-width: 1412px;
        margin: 0 auto;
        padding: 0 50px;
    `}

`

export default function Grid({children, ...rest}) {
    return(<CustomGrid {...rest}>{children}</CustomGrid>
    )
}
import React from 'react'
import styled from 'styled-components'
import Grid from '../components/Grid'
import { Typography } from '../styles'
import Theme from '../Theme'
import ScrollAnimation from 'react-animate-on-scroll'
import 'animate.css/animate.compat.css';




const PictureWork = styled.div`
    grid-row: 2;
    /* height: 168.74px; */
    width: 100%;
    height: auto;
    /* width: 299.99px; */
    /* height: auto; */
    /* background-color: ${Theme.colors.black}; */
    grid-column: 1 / -1;
    margin-bottom: 3px;
    /* background-image: url(${({image}) => image });
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; */


    ${Theme.breakpoint("md")`
        // height: 294.17px;
        width: 100%;
        height: auto;
        grid-row: 2 / 6;
        grid-column: 1 / 9;
    `}
    ${Theme.breakpoint("lg")`
        // height: 592.31px;
        width: 100%;
        height: auto;
        grid-row: 2;
        grid-column: 4 / -1;
    `}
`

const Picture = styled.img`
    width: 100%;
	height: 100%;
    background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
`;

const Text = styled(Typography.Body)`
    grid-column: 1 / -1;
    grid-row: ${({ row }) => row && row + 3};
    margin: 7px 0;
    ${Theme.breakpoint("md")`
        grid-row: ${({ tabrow }) => tabrow && tabrow + 1};
        grid-column: 9 / -1;
        margin-bottom: 15px;
    `}
    ${Theme.breakpoint("lg")`
        grid-column: ${({ start }) => start && `${start} / ${start + 3}`};
        margin: 20px 10px 0 0;
        grid-row: 3;
    `}
`
const Line = styled.div`
    grid-row: ${({ row }) => row && row + 3};
    border-color: ${Theme.colors.subgrey};
    border-style: solid;
    border-width: 0 0 1px 0;
    grid-column: 1 / -1;
    ${Theme.breakpoint("md")`
        grid-row: ${({ tabrow }) => tabrow && tabrow + 1};
        grid-column: 9 / -1;
    `}
    ${Theme.breakpoint("lg")`
        grid-row: 3;
        margin-top: 20px;
        height: 52.04px; 
        border-width: 0 2px 0 0;
        grid-column: ${({ start }) => start && `${start} / ${start + 1}`};
        align-self:center;
        `}
`

const WorkGrid = styled.div`
    margin-bottom: 40px;
    grid-column: 1 / -1;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    ${Theme.breakpoint("md")`
        margin-bottom: 130px;
        grid-column-gap: 20px;
        grid-template-columns: repeat(12, 1fr);
    `}
    ${Theme.breakpoint("lg")`
        margin-bottom: 210px;
    `}

`
export const Title = styled(Typography.Title)`
    margin-bottom: 10px;
    grid-row: 1;
    text-transform: uppercase;
    grid-column: 1 / -1;
    ${Theme.breakpoint("lg")`
        grid-column: ${({ start }) => start && `${start} / -1`};
    `}
`

const WorkTitle = styled(Title)`
    grid-row: 1;
    color: ${Theme.colors.subgrey};
    grid-column: 1 / -1;
    margin-bottom: 20px;
    padding-top: 2px;
`
const WorkContainer = styled.div`
    grid-row: 2;
    grid-column: 1 / -1;
    overflow: hidden;
    ${Theme.breakpoint("lg")`
        grid-row: 1;
    `}
`
const GridWithBorder = styled(Grid)`
    padding-top: 50px;
    ${Theme.breakpoint("md")`
        padding-top: 80px;
    `}
`

const WorkLine = styled.div`
    background-color: #999999;
    height: 1px;
    margin-bottom: 4px;

    grid-column: 1/-1;
    grid-row: 1;
`

const Wrapper = styled.div`
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #f9f9f9;

`;

function WorkSection({ canvas, title, comment_1, comment_2, comment_3}) {
    return (
        // <Wrapper>
        <WorkGrid>
            <Title start={4}>{title}</Title>
            {/* <PictureWork image={src}/> */}
            <PictureWork>
                <Picture src={canvas}/>
            </PictureWork>
            <Text start={4} row={1} tabrow={1} >{comment_1}</Text>
            <Line start={6} row={2} tabrow={1} />
            <Text start={7} row={3} tabrow={2}>{comment_2}</Text>
            <Line start={9} row={4} tabrow={2} />
            <Text start={10} row={5} tabrow={3}>{comment_3}</Text>
        </WorkGrid>
        // </Wrapper>
    )
}

export default function Work({ work }) {
    return (
        <Wrapper id="work">
            <GridWithBorder style={{marginTop:"0"}}>
                <WorkLine />
                    <WorkTitle start={1}>Work</WorkTitle>
                        <WorkContainer>
                            {work && work.map(({ ...props }, key) => 
                                <ScrollAnimation 
                                    key={key}
                                    style={{animationDuration: "2.5s"}} 
                                    animateOnce={true} 
                                    animateIn="fadeInUp">
                                    <WorkSection  {...props} />
                                </ScrollAnimation>
                                )}
                        </WorkContainer>
            </GridWithBorder>
        </Wrapper>
    )
}
import { RichText } from "prismic-reactjs"; 

export default ({ 
    data: {website_title, website_description, hero_image, multiple_images, info_email, phone, address, quote_of_the_day, social_links},
    id
}) => ({
	id,
    title: website_title,
    description: RichText.render(website_description),
    image: hero_image.url,
    heroImages: multiple_images && multiple_images.map(
        ({singleheroimage}) => singleheroimage.url
    ),
    email: RichText.render(info_email),
    phone: RichText.render(phone),
    address: RichText.render(address),
    quote: RichText.render(quote_of_the_day), 
    links: social_links && social_links.map(
        ({title, link}) => ({
            title: title,
            link: link.url,
        })
    )

});
import { RichText } from "prismic-reactjs"; 

export default ({ 
    data: {title, description, title_on_sent, description_on_sent},
    id
}) => ({
	id,
    title: title,
    description: RichText.render(description),
    title_on_sent: title_on_sent,
    description_on_sent: RichText.render(description_on_sent),
});
import React from "react";
import Hero from "../blocks/Hero";
import Work from "../blocks/Work";
import Team from "../blocks/Team"
import Contact from "../blocks/Contact"
import Quote from "../blocks/Quote";
import Partners from "../blocks/Partners";
import { RichText } from "prismic-reactjs"; 
import fetchTeam from "../scripts/get/team";
import { useGlobalState } from "../scripts/useGlobalState";
import fetchWork from "../scripts/get/work";
import arrayShuffle from 'array-shuffle';
import MiniForm from '../blocks/MiniForm';
import fetchMiniForm from '../scripts/get//miniform';
import fetchPartners from '../scripts/get/partners';
import StudioLife from "../blocks/StudioLife";
import fetchValues from '../scripts/get/values';
import FindUsOn from "../FindUsOn";
import ScrollAnimation from "react-animate-on-scroll";


const prismicData = {
    quote: "Our principles grow on the trellis-frame of rhythms. We don’t end our day until every one is tended to.",
    logos: [
        {
            title: "Design",
            description: "Design always solves problems for real people. Gather to interrogate and collaborate. Create, make and share until you uncover beauty.",
            src: "/values/lung.png"
        }, 
        {
            title: "Story",
            description: "The story you tell is the story you live into. Imagination must come before implementation. Your past narrative allows you to shape an alternative future.",
            src: "/values/slash.png"
        },
        {
            title: "Constraint",
            description: "A constraint is your invitation towards brilliance. Your no is as important as your yes. To be denied can be a delight.",
            src: "/values/greensplash.png"
        },
        {
            title: "Champion",
            description: "Champion one another, it’s the secret to relationship. Every person at the table has completely unique yet equally valuable potential. You need one another.",
            src: "/values/egg.png"
        },
        {
            title: "Excellence",
            description: "Excellence is the contribution you owe the world. There is an abundance within every person around the table. Don’t move too fast, the details are worth it.",
            src: "/values/cloud.png"
        },
        {
            title: "Life",
            description: "The story you tell is the story you live into. Imagination must come before implementation. Your past narrative allows you to shape an alternative future.",
            src: "/values/bellyshake.png"
        }]
}

const Landing = ({ admin }) => {
    const [team, setTeam] = React.useState([]);
    const [teamLoading, setTeamLoading] = useGlobalState('team');

    const [work, setWork] = React.useState([]);
    const [workLoading, setWorkLoading] = useGlobalState('team');
    const [partnersLoading, setPartnersLoading] = useGlobalState('team');


    const [members, setMembers] = React.useState([]);
    const [masks, setMaskss] = React.useState([]);
    const [partnersSection, setPartners] = React.useState([]);

    const [form, setForm] = React.useState([]);
    const [logos, setLogos] = React.useState();

    const [values, setValues] = React.useState([]);
    const [valuesLoading, setValuesLoading] = React.useState([]);

    React.useEffect(() => {
        (async function () {
            let teamData = await fetchTeam();
            let workData = await fetchWork();
            let formData = await fetchMiniForm();
            let partnersData = await fetchPartners();
            let valuesData = await fetchValues();
            setWork(workData);
            setTeam(teamData[0]);
            setForm(formData[0]);
            setPartners(partnersData[0]);
            setValues(valuesData[0]);

            setMembers(arrayShuffle(teamData[0].members));
            setMaskss(arrayShuffle(teamData[0].overlays));
            setLogos(arrayShuffle(partnersData[0].partners));



        })();
    }, []);

    React.useEffect(() => {
        team && (team.length > 0) && setTeamLoading(false);
        work && (work.length > 0) && setWorkLoading(false);
        partnersSection && (partnersSection.length > 0) && setPartnersLoading(false);
    }, [teamLoading, workLoading, partnersSection]);

    return (
        <React.Fragment>
            <Hero
                title={admin.title}
                description={admin.description}
                image={admin.image}
                heroImages={admin.heroImages}
            />
            <Partners
                title={partnersSection.title}
                partners={logos} />
            <Work work={work} />
            <Team
                description={team.description}
                members={members}
                masks={masks} />
            <StudioLife
                quote={values.quote}
                values={values.principles} />
            <Quote mainText={admin.quote} />
            <MiniForm title={form.title} description={form.description} titleAfter={form.title_on_sent} descriptionAfter={form.description_on_sent} />
            <FindUsOn email={admin.email} 
            phone={admin.phone} 
            address={admin.address}/>
        </React.Fragment>
    );
};

export default Landing;

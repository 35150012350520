import styled from 'styled-components'
import Theme from "../Theme";

const  FieldInput = styled.input`
    font-family: ${Theme.font.family.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${Theme.colors.black};
    background: #EFEFEF;
    
    padding-bottom: 7px;
    border: none;

    border-bottom: 1px solid #1F1F1F;
    width: 100%;

    ${Theme.breakpoint('md')`
        font-size: 16px;
        line-height: 19px;
    `}

    &::placeholder{
        color: ${Theme.colors.black};
    }

    &:focus{
        outline: none;
    }

`;

export default function Field({text, type, ...props}) {
    return(
        <FieldInput type={type} placeholder={text} required {...props}/>
    )
}
import React from "react";
import styled from 'styled-components'
import Grid from "../components/Grid";
import { Typography } from "../styles";
import Theme from "../Theme";
import Button from "../components/Button";
import Field from '../components/Field';
import emailjs from 'emailjs-com'
import ScrollAnimation from "react-animate-on-scroll";
import 'animate.css/animate.compat.css';

const Wrapper = styled.div`
    margin: 0;
    padding: 50px 0 40px 0;
    /* background: #EFEFEF; */

    ${Theme.breakpoint('md')`
         padding: 40px 0;
    `}
    ${Theme.breakpoint('lg')`
        padding: 100px 0 90px 0;
    `}
`;

const Text = styled.div`
    grid-column: 1/-1;
    padding-bottom: 0px;

    ${Theme.breakpoint('md')`
        grid-column: 1/7;
    `}
    ${Theme.breakpoint('lg')`
        grid-column: 1/5;
    `}
`;

const Empty = styled.div`
    grid-column: 5/7;
    grid-row: ${({row}) => row && row};

    display: none;

    ${Theme.breakpoint('lg')`
        display: block;
    `}
`;

const FieldsWrapper = styled.div`
    align-self: flex-end;
    justify-self: stretch;
    /* padding-bottom: 20px; */
    grid-column: 1/-1;

    ${Theme.breakpoint('md')`
        grid-column: span 6;
        grid-row: 3;
        padding-bottom: 0;
    `}

    ${Theme.breakpoint('lg')`
        grid-column: span 3;
        grid-row: 1;
    `}
`;

const ButtonWrapper = styled.div`
    margin-top: 20px;
    grid-column: 1/-1;
    justify-self: flex-end;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    /* padding: 10px 20px;
    border: 1px solid #1F1F1F;
    border-radius: 70px; */

    cursor: pointer;

    ${Theme.breakpoint('md')`
        margin-top: 30px;
    `}

    ${Theme.breakpoint('lg')`
        grid-column: 1/-1;
        margin-top: 0;
        grid-row: 3;
    `}

    /* &:active,:focus{
        background-color: black;

        ${Button}{
            color: white; 
        }
    } */
`;

const SubmitWrapper = styled.button`
    margin: 0;
    padding: 0;
    border: none;
    display: contents;
    cursor: pointer;
`
const ScrollContainer = styled.div`
    background-color: ${Theme.colors.grey};
`

const DescriptionWrapper = styled(Text)`
    grid-row: 2;
    padding: 0px 0px 20px 0px;
    ${Theme.breakpoint('xs', 'md')`
        height: 35.38px;
    `}
    
    ${Theme.breakpoint('md')`
        height: 47.188px;
        padding: 10px 0x 50px 0px;
    `}
    ${Theme.breakpoint('lg')`
        padding: 10px 0px 14px 0px;
    `}
`

const ScrollWrapper = styled.div`
    ${({mobile}) => mobile ? 'display: block;' : 'display: none;'}
    ${Theme.breakpoint('md')`
        ${({mobile}) => mobile ? 'display: none' : 'display: block'}
    `}
`

const NameInput = styled(Field)`
    box-sizing: border-box;
    border-radius: 0px;
`
const EmailInput = styled(Field)`
    box-sizing: border-box;
    border-radius: 0px;
`
const ErrorMessage = styled(Typography.BodySmall)`
    color: ${Theme.colors.green};
    align-self: flex-end;
    justify-self: stretch;
    padding: 7px 0px 20px 0px;
    grid-column: 1/-1;
    grid-row: ${({mobileRow}) => mobileRow || 'unset'};
    ${Theme.breakpoint('md')`
        grid-column: span 6;
        grid-row: 4;
        align-self: flex-start;
        padding: 7px 0px 0px 0px;
    `}

    ${Theme.breakpoint('lg')`
        grid-column: span 3;
        grid-row: 2;
        align-self: flex-start;
    `}
`

function MiniForm({ title, description, titleAfter, descriptionAfter }) {
    const [buttonText, SetButtonText] = React.useState("Send");
    const [formDescription, setFormDescription] = React.useState("");
    const [formTitle, setFormTitle] = React.useState("")
    const [nameInvalid, setNameInvalid] = React.useState(false);
    const [emailInvalid, setEmailInvalid] = React.useState(false);
    React.useEffect(() => {
        (async function () {
            // SetButtonText(buttonText);
            setFormDescription(description);
            setFormTitle(title);
        })();
    }, [description, title]);

    function sendEmailTest(e) {
        e.preventDefault();
        setFormTitle("Testing Success! ")
        setFormDescription("Testing is successful and let tanbir know once all screen have been tested!")
    }

    function sendEmail(e) {
        e.preventDefault();
        setFormTitle("Sending form...")
        setFormDescription("Please wait and do not close the tab.")
        emailjs.sendForm(process.env.REACT_APP_MAIL_SERVICE_ID, process.env.REACT_APP_MAIL_TEMPLATE_ID, e.target, process.env.REACT_APP_MAIL_PUBLIC_KEY)
            .then((result) => {
                setFormTitle("Thank you!")
                setFormDescription("Speak to you soon")
                SetButtonText("Sent")
            }, (error) => {
                setFormTitle("Uups!")
                setFormDescription("Seems like there has been a problem, please try again!")
            })
            .then(() => e.target.reset())
    }

    return (
    
        <Wrapper id="findUsON">
                <form onSubmit={sendEmail} onInvalid={(e) => e.target.setCustomValidity(' ')}>
                    <Grid>
                        <Text style={{ gridRow: "1" }}>
                            <Typography.Header3>
                                {formTitle}
                            </Typography.Header3>
                        </Text>
                        <DescriptionWrapper>
                            <Typography.Body>
                                {formDescription}
                            </Typography.Body>
                        </DescriptionWrapper>
                        <Empty row={'1'}/>
                        <FieldsWrapper>
                            <NameInput text='Name' 
                                onInvalid={(e) => {
                                    setNameInvalid(true)}
                                } 
                                onInput={(e) => {
                                    e.target.setCustomValidity('');
                                    setNameInvalid(false);
                                }} 
                                type="text" name="name" />
                            
                        </FieldsWrapper>
                        
                        <FieldsWrapper>
                            <EmailInput text='Email' 
                                onInvalid={(e) => {
                                    setEmailInvalid(true)}
                                }  
                                onInput={(e) => {
                                    e.target.setCustomValidity('');
                                    setEmailInvalid(false);
                                }} 
                                type="email" name="email" />
                            
                        </FieldsWrapper>
                        <Empty row={'2'} />
                        <ErrorMessage mobileRow={4}>{nameInvalid && 'Please type a name above'}</ErrorMessage>
                        <ErrorMessage>{emailInvalid && 'Please type an email above'}</ErrorMessage>
                        <ButtonWrapper>
                            <SubmitWrapper type="submit">
                                <Button small alignedRight text={buttonText} />
                            </SubmitWrapper>
                        </ButtonWrapper>
                    </Grid>
                </form>
        </Wrapper>
    )
};

export default function MiniFormAnimated({...props}) {
    return(
    <ScrollContainer>
            <ScrollWrapper>
            <ScrollAnimation
                key={'mini-form'}
                style={{ animationDuration: "1s" }}
                animateOnce={false}
                animateIn="fadeInDown">
                <MiniForm {...props}/>
                </ScrollAnimation>
            </ScrollWrapper>
            <ScrollWrapper mobile>
                <MiniForm {...props}/>
            </ScrollWrapper>
    </ScrollContainer>)
}
import React, { useState } from 'react';
import Grid from "../components/Grid";
import styled from "styled-components"
import Theme from "../Theme";
import { Typography } from "../styles";
import background from "../assets/images/Hero.jpg"
import { HashLink } from "react-router-hash-link";
import Button from "../components/Button";
import { InView, useInView } from "react-intersection-observer";



const Title = styled(Typography.Header1)`
    margin: 60px 0 0 0;
    color: ${Theme.colors.cream};
    grid-column: 1 / -1;
    grid-row: 1;
    ${Theme.breakpoint('md')`
        margin: 120px 0 0 0;
        grid-column: 1 / -2;
    `}
    ${Theme.breakpoint('lg')`
        grid-column: 1 / -1;
    `}
`;

const Description = styled.div`
    p{color: ${Theme.colors.cream};}
    grid-column: 1 / -2;
    grid-row: 2;
    font-family: ${Theme.font.family.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 21px;
    margin: 10px 0 0 0;
    ${Theme.breakpoint('md')`
        font-size: 40px;
        line-height: 50px;
        margin: 20px 0 0 0;
    `}
    ${Theme.breakpoint('lg')`
        font-size: 40px;
        line-height: 47px;
    `}
`;

const SpanTitle = styled.span`
     color: ${Theme.colors.cream};
`;

const CallToAction = styled(HashLink)`
    color: ${Theme.colors.cream};
    grid-column: 1 / -1;
    grid-row: 3;
    margin: 50px 0px;
    cursor: pointer;
    ${Theme.breakpoint('md')`
        margin: 116px 0 120px 0;
    `}

    ${Theme.breakpoint('lg')`
        margin: 110px 0 110px 0;
    `}

    text-decoration: none;

`
const Wrapper = styled.div`
    background: ${Theme.colors.black};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    ${Theme.breakpoint('lg')`
        padding: 0 50px;
        max-width: 1412px;
        margin: 0 auto;
        min-height: calc(100vh - 99px);
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    `}
`
const GridMobile = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    padding-bottom: 60px;
    ${Theme.breakpoint('md')`
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin: 0 20px;
        padding-bottom: 119.41px;
    `}
    ${Theme.breakpoint('lg')`
        grid-template-columns: repeat(7, 1fr);
        grid-column: 6 / -1;
        padding-bottom: 356px;
    `}
`
const TopLeft = styled.div`
    background: url(${({src}) => src && src });
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    transition: all 0.5s linear;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 112.55px;
    ${Theme.breakpoint('xs', 'lg')`
        ${({inView}) => inView && 'filter: grayscale(0%); -webkit-filter: grayscale(0%); -moz-filter: grayscale(0%);'}
    `}
    ${Theme.breakpoint('md')`
        grid-column: 1 / 7;
        height: 280.29px;
    `}
    ${Theme.breakpoint('lg')`
        grid-column: 1 / 5;
        height: 339px;
        margin-right: -10px;
        &:hover{
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
            -moz-filter: grayscale(0%);
        }
    `}
`
const BottomLeft = styled.div`
    background-image: url(${({src}) => src && src });
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    transition: all 0.5s linear;
    margin-left: 10px;
    grid-column: 1 / 2;
    grid-row: 2 / 2;
    height: 160px;
    ${Theme.breakpoint('xs', 'lg')`
        ${({inView}) => inView && 'filter: grayscale(0%); -webkit-filter: grayscale(0%); -moz-filter: grayscale(0%);'}
    `}
    ${Theme.breakpoint('md')`
        grid-column: 2 / 7;
        height: 356.59px;
    `}
    ${Theme.breakpoint('lg')`
        grid-column: 1 / 5;
        height: 439px;
        margin-left: 71px;
        margin-right: -10px;
        &:hover{
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
            -moz-filter: grayscale(0%);
        }
    `}
`
const Side = styled.div`
    background: url(${({src}) => src && src });
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: all 0.5s linear;
    height: 171.25px;
    margin-top: 10px;
    grid-column: 2 / 3;
    grid-row: 1 / 3;

    ${Theme.breakpoint('xs', 'lg')`
        ${({inView}) => inView && 'filter: grayscale(0%); -webkit-filter: grayscale(0%); -moz-filter: grayscale(0%);'}
    `}
    ${Theme.breakpoint('md')`
        grid-column: 7 / -1;
        margin-top: 20px;
        height: 426.51px;

    `}
    ${Theme.breakpoint('lg')`
        grid-column: 5 / -1;
        height: 361.47px;
        margin-left: 10px;
        margin-top: 232px;
        &:hover{
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
            -moz-filter: grayscale(0%);
        }
    `}
    
`
const CustomGrid = styled(Grid)`
    ${Theme.breakpoint('lg')`
        grid-template-columns: repeat(5, 1fr);
        grid-column: 1 / 6;
        padding: 0;
        min-height: unset;
        height: fit-content;
    `}
`

const ColorBackground = styled.div`
    background-color: ${Theme.colors.black};
`


export default function Hero({title, description, image, heroImages}) {

    const [ topLeftRef, topLeftInView ] = useInView({
        threshold: 0,
        rootMargin: '0px 0px -60% 0px'
      });
    const [ bottomLeftRef, bottomLeftInView ] = useInView({
        threshold: 0,
        rootMargin: '0px 0px -60% 0px'
    });
    const [ sideRef, sideInView ] = useInView({
        threshold: 0,
        rootMargin: '0px 0px -60% 0px'
      });
    return (
        <ColorBackground>
            <Wrapper image={image}>
                <CustomGrid>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    <CallToAction smooth="true" to="#findUsON">
                        <Button light caps text="Start a Conversation" />
                    </CallToAction>
                </CustomGrid>
                <GridMobile>
                    <TopLeft ref={topLeftRef} inView={topLeftInView} src={heroImages && heroImages[0]}/>
                    <BottomLeft ref={bottomLeftRef} inView={bottomLeftInView} src={heroImages && heroImages[1]}/>
                    <Side ref={sideRef} inView={sideInView} src={heroImages && heroImages[2]}/>
                </GridMobile>
            </Wrapper>
        </ColorBackground>
    )
}

import Prismic from "prismic-javascript";

export default async (docType) => {
	const api = await Prismic.api("https://rtwebsite.prismic.io/api/v2");
	const { results } = await api.query(
		Prismic.Predicates.at("document.type", docType),
		{ pageSize: 100 }
	);
	return results;
};

import React from 'react';
import { createGlobalState } from 'react-hooks-global-state';

const initialState = { 
    loading: false,
    team: true,
    work: true,
    partners: true,
    miniform: true,
    values: true
 };
const { useGlobalState } = createGlobalState(initialState);

export {useGlobalState}
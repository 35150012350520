import React from 'react'
import Grid from '../components/Grid'
import styled from 'styled-components'
import { Typography } from '../styles';
import Theme from '../Theme';
import {ReactComponent as Button} from '../assets/images/Arrow.svg';

const ValueTitle = styled(Typography.Header1)`
    ${({ visible }) => !visible && 'display: none;'}
    grid-column: 1 / -1;
    text-align: center;
    color: #6b6b6b;
    ${Theme.breakpoint("md")`
        text-align: left;
        font-size: 46.9125px;
        line-height: 59px;
        grid-column: 1 / 7;
        grid-row: 4 / 4;
    `}
    ${Theme.breakpoint("lg")`
        text-align: left;
        font-size: 100px;
        line-height: 76px;
        font-weight: 300;
        color: #1F1F1F;
    `}
`

const Title = styled(Typography.Title)`
    margin-bottom: 30px;
    text-transform: uppercase;
    grid-column: 1 / -1;
    ${Theme.breakpoint("md")`
        margin-bottom: 26px;
    `}
    ${Theme.breakpoint("lg")`
        font-weight: 400;
        grid-column: ${({ start }) => start && `${start} / -1`};
    `}
`

const Description = styled(Typography.Body)`
    ${({ visible }) => !visible && 'display: none;'}
    grid-column: 1 / -1;
    margin: 30px 0px 20px 0px;
    max-width: 215px;
    justify-self: center;
    align-self: center;
    text-align: center;
    ${Theme.breakpoint("md")`
        text-align: left;
        justify-self: flex-start;
        align-self: flex-start;
        grid-column: 1 / 7;
        grid-row: 5 / 5;
        margin: 20px 0 0 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 23.6px;
        max-width: unset;
    `}
    ${Theme.breakpoint("lg")`
        margin: 55.58px 0 0 0;
        font-weight: 400;
        font-size: 28px;
        line-height: 33.04px;
        max-width: 453px;
    `}
`
const Logo = styled.div`
    ${({ visible }) => !visible && 'display: none;'}
    grid-column: 1 / -1;
    background-image: url(${({src}) => src && src});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 138px;
    height: 138px;
    margin: 33px 0px 0px 0px;
    justify-self: center;
    align-self: center;
    ${Theme.breakpoint("md")`
        grid-column: 9 / -1;
        grid-row: 4 / 6;
        margin: 0;
        height: 145px;
        width: 145px;
    `}
    ${Theme.breakpoint("lg")`
        height: 205px;
        width: 205px;
    `}
`
const MobileLogo = styled.div`
    display: block;
    background-image: url(${({src}) => src && src});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 60px;
    height: 60px;
    margin: 30px 0px 27px 0px;
    grid-column: span 3;
    justify-self: center;
    align-self: center;
    ${Theme.breakpoint("md")`
        display: none;
    `}
    ${({ visible }) => !visible && 'display: none;'}
`
const DesktopLogo = styled.div`
    align-self: center;
    justify-self: center;
    grid-column: span 2;
    display: none;
    background-image: url(${({src}) => src && src});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 50px;
    ${Theme.breakpoint("md")`
        display: block;
        height: 76px;
        width: 76px;
    `}
    ${Theme.breakpoint("md")`
        display: block;
        height: 100px;
        width: 100px;
    `}
    
`

const Quote = styled.div`
    grid-column: 1 / -1;
    font-family: ${Theme.font.family.secondary};
    font-weight: 400;
    font-size: 18px;
    font-style: 23px;
    margin-bottom: 10px;
    color: #6B6B6B;
    ${({ visible }) => !visible && 'display: none;'}
    ${Theme.breakpoint("md")`
        font-family: ${Theme.font.family.secondary};
        font-size: 46.9125px;
        font-style: 59px;
        margin-bottom: unset;
    `}
    ${Theme.breakpoint("lg")`
        font-family: ${Theme.font.family.primary};
        color: ${Theme.colors.black};
        font-weight: 300;
        font-size: 71px;
        font-style: 76px;
    `}
`

const CustomGrid = styled(Grid)`
    margin-top: 0;
    ${Theme.breakpoint("md")`
    `}
`
const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0;
    padding: 0 0 0 0;
    height: 600px;
    overflow: hidden;
    background-color: #fffbef;
    ${Theme.breakpoint("md")`
        display: block;
        padding: 51.12px 0 0 0;
        height: 423.24px;
    `}
    ${Theme.breakpoint("lg")`
        padding: 120.86px 0 0 0;
        height: 620px;
    `}
`;

const StudioLifeLine = styled.div`
    background-color: ${Theme.colors.black};
    height: 1px;
    margin-top: 26px;
    margin-bottom: 4px;
    grid-column: 1/-1;
    ${Theme.breakpoint("md")`
        height: 2.61px;
    `}
    ${Theme.breakpoint("lg")`
        height: 1px;
        margin-top: 91.51px
    `}
`
const StudioLifeTitle = styled(Title)`
    /* grid-row: 1; */
    color: ${Theme.colors.black};
    grid-column: 1 / -1;
    padding-top: 2px;

`
const BackButton = styled(Button)`
    justify-self: center;
    align-self: flex-end;
    width: 49.61px;
    height: 20.37px;
`
const BackButtonContainer = styled.div`
    grid-column: 1 / -1;
    width: 100%;
    padding-bottom: 63px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    ${({ visible }) => visible && 'display: none;'}
    ${Theme.breakpoint("md")`
        display: none;
    `}
`

export default function StudioLife({ values, quote }) {
    const [selected, setSelected] = React.useState(null)

    //clock states
    const selectedRef = React.useRef(selected);
    React.useEffect(() => { selectedRef.current = selected; }, [selected]);

    //when selected, title, description and logo change

    //invisible state link to display css

    //quote, title and description all in same grid row, overlayed

    //for mobile, heavy css required.
    return (
        <Wrapper id="our_principles" onMouseLeave={() => setSelected(null)}>
            <CustomGrid>
                {values?.map(({ src }, key) => <DesktopLogo src={src} key={'desktop -'+ key} onMouseEnter={() => setSelected(values[key])} onClick={() => setSelected(values[key])}  />)}
                <StudioLifeLine />
                <StudioLifeTitle start={1}>Our Principles</StudioLifeTitle>
                {quote && <Quote visible={!selected}>{quote}</Quote>}
                {values?.map(({ src }, key) => <MobileLogo src={src} visible={!selected} key={'mobile -'+ key} onClick={() => setSelected(values[key])} />)}
                <ValueTitle visible={selected} >{selected?.title}</ValueTitle>
                <Description visible={selected} >{selected?.description}</Description>
                <Logo visible={selected} src={selected?.src} />
            </CustomGrid>
            <CustomGrid>
                <BackButtonContainer visible={!selected}>
                    <BackButton onClick={() => setSelected(null)}/>
                </BackButtonContainer>
            </CustomGrid>
        </Wrapper>)
}
import { RichText } from "prismic-reactjs"; 


export default ({ data, id }) => ({
	id,
    description: RichText.render(data.description),
    members: data.members && data.members.map(
        ({photo, full_name, role }) => ({
            photo: photo.url,
            name: full_name,
            role: role,
        })
    ),
    overlays: data.overlays && data.overlays.map(({image})=>image.url),
});
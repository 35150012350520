import React from "react";  
import styled from "styled-components";
import { Typography } from "../styles";
import Theme from "../Theme";

const Text = styled(Typography.BodySmall)`
    color: ${({light})=> light ? '#FFFFFF': ' #1F1F1F'};
    padding-right: 10px;
    transition: 0.5s ease-in-out;
`;

const CAPSTitle = styled(Typography.Title)`
    color: ${({light})=> light ? '#FFFFFF': ' #1F1F1F'};
    padding-right: 10px;
    transition: 0.5s ease-in-out;
`;

const Arrow = styled.svg`
    width: ${({small})=> small ? '50px': ' 85px'};
    height: 20px;
    transition: 0.5s ease-in-out;
    overflow: visible;

    ${Theme.breakpoint("md")`
        width: ${({small})=> small ? '50px': ' 197px'};
    `}
`;

const Wrapper = styled.span`
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;


    ${({ small }) =>
		small &&
		`
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            border: 1px solid #1F1F1F;
            border-radius: 70px;
		`}

    

    &:hover{
        ${Arrow}{
            transform: ${({small})=> small ? '0': ' translateX(20px)'};
            left: ${({small})=> small ? '0': ' 30px'};
            
        }
    }

    &:active,:focus{
        ${Arrow}{
            transform: translateX(0);
            width: ${({small})=> small ? '1px': ' 125px'};
        }

        ${({ small }) =>
                small &&
                `
                  background-color: black;
                `}

        ${Text}{
            ${({ small }) =>
                small &&
                `
                  color: white; 
                `}
        }
    }

    ${Theme.breakpoint("md")`
        &:active,:focus{
            ${Arrow}{
                transform: translateX(0);
                width: ${({small})=> small ? '1px': ' 297px'};
            }
        }   
    `}    
`;

export default function Button({text, light, caps, small,  ...props}) {
    return(
        <Wrapper small={small}>
            {caps ? (
                <CAPSTitle light={light}>{text}</CAPSTitle>
            ):(
                <Text light={light}>{text}</Text>
            )}
            
            {light ? (
                <Arrow  small={small}>
                    <defs>
                        <marker id="mw" markerWidth="10" markerHeight="16" 
                        refX="0" refY="1" viewBox="0 0 1 2" >
                            <polygon points="0,0 1,1 0,2" fill="#FFFBEF"/>
                        </marker>
                    </defs>
                    <line stroke="#FFFBEF" strokeWidth="1" x1="0" y1="50%" x2="100%" y2="50%" markerEnd="url(#mw)"/>
                </Arrow>
            ):(
                <Arrow small={small}>
                    <defs>
                        <marker id="mb" markerWidth="10" markerHeight="16" 
                        refX="0" refY="1" viewBox="0 0 1 2">
                            <polygon points="0,0 1,1 0,2" fill="black"/>
                        </marker>
                    </defs>
                    <line stroke="black" strokeWidth="1" x1="0" y1="50%" x2="100%" y2="50%" markerEnd="url(#mb)"/>
                </Arrow>
            )}
        </Wrapper>
    )
}
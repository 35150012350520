import React from "react";  
import styled from 'styled-components'
import Grid from "../components/Grid";
import { Typography } from "../styles";
import Theme from "../Theme";

const QuoteText = styled(Typography.Header2)`
    grid-column: 1 / -1;
    margin: 50px 0;
    ${Theme.breakpoint("md")`
        margin: 50px 0 150px 0;
    `}
    
`

export default function Quote({mainText}) {
    return(
        <Grid style={{position: 'relative', zIndex: '5', backgroundColor: 'white'}}>
            <QuoteText>{mainText}</QuoteText>
        </Grid>
    )
}
import { RichText } from "prismic-reactjs"; 

export default ({data:{ 
    project_title__work_type, canvas, comment_1, comment_2, comment_3
}, id, slugs}) => ({
    id,
    slug: slugs[0],
    title: project_title__work_type,
    canvas: canvas.url,
    comment_1: RichText.render(comment_1),
    comment_2: RichText.render(comment_2),
    comment_3: RichText.render(comment_3),
});
import React, { useState } from "react";
import styled, {keyframes} from "styled-components";
import Theme from './Theme';
import {Typography} from './styles';
import { HashLink } from "react-router-hash-link";

const Padding = styled.div`
	height: 50px;
	background-color: #FFFFFF;
	${Theme.breakpoint("md")`
        height: 79px;
    `}
	${Theme.breakpoint("lg")`
        height: 99px;
    `}
	
	transform: translateY(${({ hiddenNav }) => (hiddenNav ? "-100%" : "0")});
	transition: ${({ hiddenNav }) => (hiddenNav ? "450ms" : "200ms")};
	/* transition-delay: ${({ delayOn}) => (delayOn ? "0" : "749ms")}; */
	transition-delay: ${({ delayOn}) => (delayOn ? "749ms" : "0")};
	
`;

const Wrapper = styled.nav`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 50px;
	background-color: #FFFFFF;

	z-index: 1000; //998
	padding: 0;

    ${Theme.breakpoint("md")`
        height: 79px;
    `}

    ${Theme.breakpoint("lg")`
        height: 99px;
    `}
	
	transform: translateY(${({ hiddenNav }) => (hiddenNav ? "-100%" : "0")});
	transition: ${({ hiddenNav }) => (hiddenNav ? "450ms" : "200ms")};
	/* transition-delay: ${({ delayOn}) => (delayOn ? "0" : "749ms")}; */
	transition-delay: ${({ delayOn}) => (delayOn ? "749ms" : "0")};

	//delay on = sidebar==true && hidden Nav == false

	//delay off = sidebar==false && hidden nav == true
`;

const ContentWrapper = styled.div`
	width: 100%;
	height: 50px;
	max-width: 1512px;
	background-color: #FFFFFF;

	display: flex;
	align-items: center;

	padding: 12px 10px;
	box-sizing: border-box;
	margin: 0 auto;

    ${Theme.breakpoint("md")`
        padding: 20px;
        height: 79px;
    `}

	${Theme.breakpoint("lg")`
        padding: 30px 55.5px;
		height: 99px;
    `}
`;

const DesktopItems = styled.section`
	display: none;

	${Theme.breakpoint("md")`
        display: inline-block;
    `}
`;

const StyledLink = styled.a`
	text-decoration: none;
	color: ${Theme.colors.black};
	&:focus,
	&:hover,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
		color: ${Theme.colors.black};
	}
	cursor: pointer;
`;

const rotating = keyframes`
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(-360deg);
    }
`;

const Logo = styled.img.attrs({
	src: require("./assets/images/roundtable-icon.svg").default,
})`
	width: 26px;
	display: block;
	margin-right: 30px;

	${Theme.breakpoint("md")`
        width: 40px;
    `}

	${Theme.breakpoint("lg")`
        width: 40px;
    `}
`;


const SpinningLogo = styled(Logo)`
	animation: ${rotating} 9s linear infinite;
`;


const Tagline = styled(Typography.BodySmall)`
	font-weight: bold;

	color: ${Theme.colors.black};

	margin: 0;
	margin-right: auto;

	${Theme.breakpoint("xs", "md")`
		display:none;
	`}

`;

const TaglineMobile = styled(Typography.BodySmall)`
	font-style: normal;
	font-weight: 500;

	color: ${Theme.colors.black};

	margin: 0;
	margin-right: auto;

	${Theme.breakpoint("md")`
		display:none;
	`}

`;

const Item = styled(Typography.BodySmall)`
	display: inline-block;
	margin: 0;
	padding: 0;
	/* &:hover {
		color: ${Theme.colors.black};

		later when we have more pages

	} */

	${Theme.breakpoint("md")`
		padding-right: 102px;
    `}
	
`;

const HashText = styled(HashLink)`
	text-decoration: none;
`;

const ButtonText = styled(Typography.BodySmall)`
	font-style: normal;
	color: ${Theme.colors.black};
`;
// might be changed to .button later on
const Button = styled(HashLink)`
	width: 92px;
	height: 39px;
	display: inline-flex;
  	align-items: center; 

	background-color: ${Theme.colors.white};

	padding: 1px 7px;	


	border: 1px solid #1F1F1F;
	border-radius: 70px;

	justify-content: center;
	align-items: center;
	text-decoration: none;
	cursor:pointer;

	&:hover{
		text-decoration: none;
	}
	&:active{
		text-decoration: none;
		background-color: ${Theme.colors.black};
		${ButtonText}{
			color: ${Theme.colors.white};
		}
	}


`;



const MobileItem = styled.section`
	width: 100%;
	/* margin-left: auto; */
	${Theme.breakpoint("md")`
        display: none;
    `}

	transition: all 0.5s ease-in-out;
	position: relative; 
	display: flex;
	justify-content: flex-end;
	align-items: center;
	top: 0;
	right: 22.24px;

	cursor: pointer;

`;

const BurgerLine = styled.div`

	&::before, &::after{
		content: '';
		position: absolute;
		width: 20.24px;
		height: 2.83px;
		transition: all 0.5s ease-in-out;
		background: ${Theme.colors.black};	
	}

	&::before{
		transform: ${({ show }) => (show ? "rotate(45deg) translate(2px, -2px)" : " translateY(-2.83px)")};
	}
	&::after{
		transform: ${({ show }) => (show ? "rotate(-45deg) translate(2px, 2px)" : " translateY(2.83px)")};
	}
`;

const ListWrapper = styled.ul`
	height: auto; //100%;
	list-style-type: none;

	margin: 25px 10px 15px 10px;
	padding: 0;
`;

const ListItem = styled(HashLink)`
	margin: 0 10px;
	padding: 0 0 20px 0;
	text-decoration: none;
	text-align: right;
	color: ${Theme.colors.black};
`;

const ListItemA = styled.a`
	margin: 0 10px;
	padding: 0 0 20px 0;
	text-decoration: none;
	text-align: right;
	color: ${Theme.colors.black};
`;

const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 50px;
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	z-index: 1000;

	${Theme.breakpoint("md")`
        top: 79px;
    `}
	${Theme.breakpoint("lg")`
        top: 99px;
    `}
`;

const SideBar = styled.nav`
	background-color: ${Theme.colors.white};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: right;

	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	
   
`;

const SidebarWrapper = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	top: 0px;
	overflow: hidden;
	z-index: 1001;

	pointer-events: ${({ show }) => (show ? "all" : "none")};

	

	top: 49px;
	/* padding-top: 50px; */

	${Theme.breakpoint("md")`
		top: 78px;
		display: none;
    `}
	${Theme.breakpoint("lg")`
		top: 98px;
		display: none;
	`}

	${SideBar} {
		transform: translateY(${({ show }) => (show ? "0px" : "-100%")});
		transition: ${({ show }) => (show ? "350ms" : "750ms")};
		transition-delay: ${({ show }) => (show ? "200ms" : "0")};
	}

`;

const navItems = [
	{
		title: "Work",
		link: "/#work",
	},
	{
		title: "Team",
		link: "/#team",
	},
	
	{
		title: "Our Principles",
		link: "/#our_principles"
	}
];

const mobileLinks = [
	// {
	// 	title: "Contact",
	// 	link: "mailto:info@round-table.co.uk",
	// },
	{
		title: "LinkedIn",
		link: "https://www.linkedin.com/company/roundtablestudio/",
	},
	{
		title: "Instagram",
		link: "https://www.instagram.com/roundtable_studio/?hl=en-gb",
	}
];


const Nav = ({spinning, hiddenNav}) => {

	const [sidebar, setSideBar] = useState(false);
	const showSidebar = () => setSideBar(!sidebar);
	const closeSidebar = () => setSideBar(false);

	// console.log("link title", links[0].title);

	return (
		<React.Fragment>
			<SidebarWrapper show={sidebar && !hiddenNav}>
				<SideBar className={sidebar ? "nav-menu active" : "nav-menu"}>
					<ListWrapper style={{marginTop: "0"}} onClick={showSidebar}>
						{navItems.map(({ title, link }, key) => (
							<ListItem key={key} smooth="true" to={link}>
								<Typography.MobileHeader3>
									{title}
								</Typography.MobileHeader3>
							</ListItem>
						))}
						<ListItem smooth="true" to="#findUsON">
								<Typography.MobileHeader3>
									Contact
								</Typography.MobileHeader3>
						</ListItem>
					</ListWrapper>
					<ListWrapper onClick={showSidebar}>
						{mobileLinks.map(({ title, link }, key) => (
							<ListItemA key={key} href={link} target="_blank">
								<Typography.MobileHeader3>
									{title}
								</Typography.MobileHeader3>
							</ListItemA>
						))}
					</ListWrapper>
				</SideBar>
			</SidebarWrapper>
				<Wrapper hiddenNav={hiddenNav} delayOn={hiddenNav && sidebar}>
					<ContentWrapper>

						{ !hiddenNav && sidebar && <Overlay onClick={closeSidebar}></Overlay>}
						
						<Tagline>
							{spinning ? (
								<StyledLink href="/"><SpinningLogo /></StyledLink>
							) : (
								<StyledLink href="/"><Typography.BodySmall>Roundtable</Typography.BodySmall></StyledLink>								
							)}
						</Tagline>

						<TaglineMobile>
							{spinning ? (
								<StyledLink href="/"><SpinningLogo /></StyledLink>
							) : (
								<StyledLink href="/"><Logo /></StyledLink>
							)}
						</TaglineMobile> 

						<MobileItem show={sidebar}>
							<Item onClick={showSidebar}>
								<BurgerLine show={sidebar}/>
							</Item>
						</MobileItem>

						<DesktopItems>
							{navItems.map(({ title, link }, key) => (
								<Item key={key}><HashText  smooth="true"
									to={link}>
										
								{/* <StyledLink href={link}> */}
								{/* use styled link later when we have more pages */}
									{title}
								{/* </StyledLink> */}

								</HashText></Item>
							))}
							<Button smooth="true" to="#findUsON">
								<ButtonText>Contact</ButtonText>
							</Button>
						</DesktopItems>
					</ContentWrapper>
				</Wrapper>
				<Padding hiddenNav={hiddenNav} delayOn={hiddenNav && sidebar}/>
		</React.Fragment>
	);
};

export default Nav;

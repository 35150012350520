import React, { useEffect } from 'react'
import styled from 'styled-components'
import Grid from '../components/Grid'
import { Typography } from '../styles'
import Theme from '../Theme'

import ai from "../assets/images/ai.png";
import albany from "../assets/images/albany.png";
import bfl from "../assets/images/bfl.png";
import gs from "../assets/images/gs.png";
import kxc from "../assets/images/kxc.png";
import whales from "../assets/images/whale.png";



const Wrapper = styled.div`
    width: 100%;
    height: 100%;

    background-color:  ${Theme.colors.white};

    margin: 0;
    padding: 50px 0 80px 0;
    /* padding-left: 10px; */

    ${Theme.breakpoint("md")`
        padding: 62px 0 53px 0;
        /* padding-left: 21px; */
    `}

    ${Theme.breakpoint("lg")`
        padding: 79px 0 150px 0;
        /* padding-left: 50px; */
    `}
`;

const Title = styled(Typography.Header4)`
 grid-column: 2 / -2;
 text-align: center;
 margin-bottom: 34px;

    ${Theme.breakpoint("md")`
        grid-column: 1 / -1;
        text-align: center;
    `}

    ${Theme.breakpoint("lg")`
        text-align: left;
        grid-row: 1 / 3;
        grid-column: 1 / 7;
        max-width: 388.24px;
    `}

`;

const PartnerWrapper = styled.div`
    margin: 0;
    padding: 0;
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    transform: scale(${({ hide }) => (hide ? 0.8 : 1)});
    grid-column: span 3;
    text-align: center;
    transition-property: opacity, transform;
    transition-duration: 1.2s;
    width: 111.16px;
    justify-self: center;

    height: 70px;
    min-height: 25px;

    background-image: url("${({ image }) => (image !== "") && image}");
    background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

    ${Theme.breakpoint("md")`
        max-width: 145.52px;
        width: 100.52px;
        text-align: left;
        height: 65x;
        grid-column: span 4;
    `}

    ${Theme.breakpoint("lg")`
        width: 126.41px;
        max-width: 146.41px;
        grid-column: span 2;
        height: 65px;
    `}

   

`;


const Logo = styled.div`
    background-image: url("${({ image }) => (image !== "") && image}");
    background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;


`;

const PartnerGrid = styled(Grid)`
    row-gap: 35px;
    ${Theme.breakpoint("md")`
        grid-row-gap: 53px;
    `}
    ${Theme.breakpoint("lg")`
        grid-auto-flow: column;
        row-gap: 70px;
    `}
`





export default function Partners({ title, partners }) {

    const [logos, setLogos] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [hide, setHide] = React.useState(false);
    const [index, setIndex] = React.useState(0);
    const indexRef = React.useRef(index);
    const logosRef = React.useRef(logos);
    const logosToShow = 6;
    
    // const createEmptyArray = (arr,n) => {
    //     while (arr.length < logosToShow) {
    //         arr.push("");
    //     }
    //     return arr
    // }

    const findMultiplier = arr => {
        let n = 1;
        while (arr && ((arr.length * n) % logosToShow) > 0) {
            n ++;
        }
        return n;
    }

    const repeatArray = (arr, n) => [].concat(...Array.from({ length: n }, () => arr));


    useEffect(() => { 
        indexRef.current = index; 
        let i = indexRef.current;
        let arr = logosRef.current;
        let sliced = arr.slice(i * logosToShow, (logosToShow + i * logosToShow))
        setSelected(sliced);
        // sliced.length > 0 ? (sliced.length == logosToShow ? setSelected(sliced) : setSelected(createEmptyArray(sliced,2))) : setSelected(sliced)
        
    }, [index]);

    useEffect(() => { logosRef.current = logos; }, [logos]);
    const showNext = () => {
        setHide(true);
        // console.log("before if", partners)
        setTimeout(() => {
            let i = indexRef.current
            let l = logosRef.current
            // let arr = logosRef.current
            setIndex(() => {
                if ((i + 1) * logosToShow >= l.length) {
                    return 0
                } else {
                    return i + 1
                }
            });
            
            setHide(false);
        }, 1250);
    };


    React.useEffect(() => {
        (async function () {
            setLogos(repeatArray(partners, findMultiplier(partners))|| []);
            setSelected((partners && partners.slice(0,logosToShow)) || [])
        })();
    },[partners]);


    React.useEffect(() => {
        const interval = setInterval(() => {
            showNext()
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Wrapper>
            <PartnerGrid>
                <Title>{title}</Title>
                    {selected && selected.map((logo, key) => (
                        <PartnerWrapper hide={hide} image={logo} key={key} />
                    ))}
            </PartnerGrid>
        </Wrapper>
    )
}
import React from "react";
import styled from "styled-components";
import Grid from "./components/Grid";
import { Typography } from "./styles";
import logo from "./assets/images/roundtable-icon.svg"
import Theme from "./Theme";
import ScrollAnimation from "react-animate-on-scroll";
import 'animate.css/animate.compat.css';

const Wrapper = styled.div`
    margin: 0;
    padding: 0;

    background-color: #EFEFEF;

    padding: 10px 0 80px 0;

    ${Theme.breakpoint("md")`
        padding: 40px 0;
    `}
     ${Theme.breakpoint("lg")`
        padding: 50px 0;
    `}
`

const Title = styled(Typography.Header3)`
    grid-column: 1 / -1;
    margin-bottom: 20px;
    ${Theme.breakpoint("md")`
       margin-bottom: 50px;
    `}

    ${Theme.breakpoint("lg")`
       grid-row: 1;
       grid-column: 1/4;
    //    align-self: center;
       margin-bottom: 0;
    `}

`
const Email = styled.a`
    grid-column: 1 / -1;
    margin-bottom: 20px;

    text-decoration: none;
    &:active,:hover,:focus{
        text-decoration: none;
    }

    ${Theme.breakpoint("md")`
        grid-column: span 4;
        border-right: 1px solid #1F1F1F;
        align-self: center;
    `}
    ${Theme.breakpoint("lg")`
        grid-row: 1;
        grid-column: span 3;
        display: flex;
        justify-content: center;
        border-right: 1px solid #1F1F1F;
       
        height: 100%;
        align-items: center;
        margin: 0;
    `}
`
const PhoneNumber = styled.a`
    grid-column: 1 / -1;
    margin-bottom: 20px; 

    text-decoration: none;
    &:active,:hover,:focus{
        text-decoration: none;
    }

    ${Theme.breakpoint("md")`
        grid-column: span 4;
        display: flex;
        justify-content: center;
        align-self: center;
        border-right: 1px solid #1F1F1F;
    `}
    ${Theme.breakpoint("lg")`
        grid-row: 1;
        grid-column: span 3;
        align-self: center;

        height: 100%;
        align-items: center;
        margin: 0;
    `}
`
const Address = styled.a`
    grid-column: 1 / -1;
    margin-bottom: 20px; 
    display: flex;

    text-decoration: none;
    &:active,:hover,:focus{
        text-decoration: none;
    }

    ${({ tablet }) =>
		tablet &&
		`
		display: none;
	`}

    ${Theme.breakpoint("md")`
        display: none;
        grid-column: span 4;
        justify-self: center;

        ${({ tablet }) =>
		tablet &&
		`
            display: flex;
        `}
    `}
    ${Theme.breakpoint("lg")`
        display: flex;
        grid-row: 1;
        grid-column: span 3; 
        height: 100%;       
        align-items: center;
        margin: 0;
        ${({ tablet }) =>
		tablet &&
		`
            display: none;
        `}
        
    `}
`

const MobileLine = styled.hr`
    grid-column: 1 / 1;
    margin-bottom: 20px;
    ${Theme.breakpoint("md")`
        display: none;
    `}
`
const ScrollWrapper = styled.div`
    ${({mobile}) => mobile ? 'display: block;' : 'display: none;'}
    ${Theme.breakpoint('md')`
        ${({mobile}) => mobile ? 'display: none' : 'display: block'}
    `}
`

const ScrollContainer = styled.div`
    background-color: ${Theme.colors.grey};
`


function FindUsOnAnimated() {
    return(
         <Wrapper>
            <Grid>
                <Title>Find us on</Title>
                <Email href="mailto:info@round-table.co.uk">
                    <Typography.Body >info@round-table.co.uk</Typography.Body></Email>
                <MobileLine/>
                <PhoneNumber href="tel:+4420-3322-2420">
                    <Typography.Body>+44 (0) 20 3322 2420</Typography.Body></PhoneNumber>
                <MobileLine/>
                <Address tablet target="_blank" href="https://goo.gl/maps/cSXWE29vG6aEVrGz7"><Typography.Body>86-90 Paul Street, <br/> London, EC2A 4NE</Typography.Body></Address>
                <Address target="_blank" href="https://goo.gl/maps/cSXWE29vG6aEVrGz7"><Typography.Body>86-90 Paul Street, London, EC2A 4NE</Typography.Body></Address>
            </Grid>
        </Wrapper>
     )
 }

 export default function FindUsOn() {
    return(
        <ScrollContainer>
            <ScrollWrapper>
                <ScrollAnimation
                    key={'find-us-on'}
                    style={{ animationDuration: "1s" }}
                    animateOnce={false}
                    animateIn='fadeInDown'>
                        <FindUsOnAnimated/>
                </ScrollAnimation>
            </ScrollWrapper>
            <ScrollWrapper mobile>
                <FindUsOnAnimated/>
            </ScrollWrapper>
        </ScrollContainer>
    )
 };

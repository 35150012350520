import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from './pages/Landing';
import Nav from './Nav';
import React, { useState } from "react";
import Footer from "./Footer";
import FindUsOn from "./FindUsOn";
import fetchAdmin from "./scripts/get/admin";

const foooterData = {
  links: [
    {
      text: "LinkedIn",
      url: "https://www.linkedin.com/company/roundtablestudio/"
    },
    {
      text: "Instagram",
      url: "https://www.instagram.com/roundtable_studio/?hl=en-gb"
    },
    // {
    //   text: "Careers",
    //   url: "url"
    // },
    {
      text: "Contact",
      url: "mailto:info@round-table.co.uk"
    }
  ],
  descriptions: ["+44 (0) 20 3322 2420",
    "info@round-table.co.uk",
    "86-90 Paul Street", "London", "EC2A 4NE"]
}

export default function App() {
  const [spinning, setSpinning] = useState(false);
  const startSpinning = () => setSpinning(!spinning);
	const stopSpinning = () => setSpinning(false);
  const [hiddenNav, setHiddenNav] = useState(false);

  const [admin, setAdmin] = React.useState([]);
  
  React.useEffect(() => {
		(async function () {
      let adminData = await fetchAdmin();
      setAdmin(adminData[0]);
      

			let pageOffSet = 0;
      setHiddenNav(false);
      let lastScrollTop = 0;

			window.addEventListener("scroll", () =>{
			pageOffSet = window.scrollY;

      if(pageOffSet===0){
        stopSpinning();
      }

      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop){
          if(pageOffSet > 1 && pageOffSet <= 200){
              startSpinning();
          } else if(pageOffSet > 201){
            
              setHiddenNav(true);
              stopSpinning();
          } else{
              setHiddenNav(false);
              stopSpinning();
          }
      } else {
          if(pageOffSet > 1 && pageOffSet <= 200){
              startSpinning();
              setHiddenNav(false);
          } else if (pageOffSet <=1){
              stopSpinning();
          }       
      }
      lastScrollTop = st <= 0 ? 0 : st;
			});
		})();
	}, []);

  return (
    <BrowserRouter>
      <Nav spinning={spinning} hiddenNav={hiddenNav} 
      // links={admin.links}
      />
      <Routes >
        <Route path="/" element={
          <React.Fragment>
            <Landing  admin={admin}/>
          </React.Fragment>
      } />
      </Routes>
      <Footer links={foooterData.links} descriptions={foooterData.descriptions}/>
    </BrowserRouter>
  );
};
